.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.login-container .error {
  color: red;
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 10px;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.login-container input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.login-container button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #45a049;
}

.login-container a {
  text-align: center;
  display: block;
  margin-top: 10px;
  color: #007BFF;
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}