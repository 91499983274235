/* Estilo padrão (telas maiores) */
.home-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  background-color: #228b22;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.menu-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  color: #228b22;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
}

.menu-button:hover {
  background-color: #d3ffd3;
}

.main-content {
  background-color: #90ee90;
  width: 80%;
  padding: 20px;
}

/* Estilo responsivo para telas menores (celulares) */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .sidebar {
    display: none;
  }

  .appbar {
    background-color: #228b22;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
  }

  .appbar-button {
    background-color: white;
    color: #228b22;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 16px;
    margin: 0 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }

  .appbar-button:hover {
    background-color: #d3ffd3;
  }

  .main-content {
    width: 100%;
  }
}

/* Estilo para o botão ativo */
.menu-button.active,
.appbar-button.active {
  background-color: #006400; /* Verde escuro */
  color: white; /* Texto branco */
  font-weight: bold; /* Destaca o texto */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Sombra mais evidente */
}

/* Hover continua aplicável ao botão ativo */
.menu-button.active:hover,
.appbar-button.active:hover {
  background-color: #004d00; /* Cor um pouco mais escura */
}
